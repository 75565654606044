import { useCookies } from 'react-cookie';
import { useMutation, useQuery } from 'react-query';
import { supabase } from '../../../utils/supabaseConfig';
import moment from 'moment';

export const useSessions = () => {
  const [cookies] = useCookies(['lepole_admin']);
  const id = cookies?.lepole_admin?.id;
  const dateString = new Date().toISOString().substring(0, 10);

  const query = useQuery(['sessions', id], () => {
    return supabase
      .from('session')
      .select('*')
      .eq('user_id', id)
      .eq('date', dateString);
  });
  return query;
};

export const useAllSessions = () => {
  const query = useQuery(['all-sessions'], () => {
    return supabase
      .from('session')
      .select('*', { count: 'exact' })
      .order('created_at', { ascending: false });
  });
  return query;
};

export const usePaginatedAllSessions = (
  page,
  limit = 20,
  sortBy = 'created_at',
  ascending = false,
) => {
  const skip = (page - 1) * limit || 0;

  const query = useQuery(
    ['all-sessions-paginated', page, sortBy, ascending],
    () => {
      return supabase
        .from('session')
        .select('*', { count: 'exact' })
        .order(sortBy, { ascending })
        .range(skip, skip + limit - 1 || 0);
    },
  );
  return query;
};

export const useAllActiveSessions = () => {
  const query = useQuery(['all-active-sessions'], () => {
    return supabase.rpc('get_all_active_sessions');
  });
  return query;
};

export const useTotalActiveSessions = () => {
  const query = useQuery(['total-active-sessions'], () => {
    return supabase.rpc('get_total_active_sessions');
  });
  return query;
};

export const useAllUpcomingSessions = () => {
  const date = new Date();
  const dateString = date.toISOString();

  const query = useQuery(['all-upcoming-sessions'], () => {
    return supabase
      .from('session')
      .select('*')
      .eq('isCancelled', false)
      .gte('startTime', dateString);
  });
  return query;
};

export const useTotalSessions = () => {
  const [cookies] = useCookies(['lepole_admin']);
  const id = cookies.lepole_admin?.id;

  const query = useQuery(['total-sessions', id], () => {
    return supabase.rpc('get_total_sessions', {
      userid: id,
    });
  });
  return query;
};

export const useTotalCoins = () => {
  const [cookies] = useCookies(['lepole_admin']);
  const id = cookies.lepole_admin?.id;

  const query = useQuery(['total-coin', id], () => {
    return supabase.rpc('get_total_coins_spent', {
      userid: id,
    });
  });
  return query;
};

export const useTotalCoinsPurchased = () => {
  const query = useQuery(['total-coin'], () => {
    return supabase.rpc('get_total_coins_purchased');
  });
  return query;
};

export const useGetAvailableSession = (date) => {
  const dateString = moment(date).format('YYYY-MM-DD');

  const query = useQuery(['available-session', dateString], () => {
    return supabase.rpc('get_sessions_for_date', {
      input_date: dateString,
    });
  });
  return query;
};
