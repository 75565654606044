import { useMutation, useQuery } from 'react-query';
import { supabase } from '../../../utils/supabaseConfig';

export const useCustomers = (page, limit = 1000) => {
  const query = useQuery(['user', page], () => {
    return supabase.auth.admin.listUsers({
      page: page || "",
      perPage: limit,
    })
  });
  return query;
};

export const useDeleteUser = () => {
  const mutation = useMutation(['user'], (id) => {
    return supabase.auth.admin.deleteUser(id);
  });

  return mutation;
};


export const useCustomer = (id) => {
  const query = useQuery(['user', id], () => {
    return supabase.auth.admin.getUserById(id)
  });
  return query;
};

