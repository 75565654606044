import React, { useEffect, useState, useMemo } from 'react';
import { plans } from '../../../utils/dummyData';
import { Button } from '@deposits/ui-kit-react';
import { formatTime } from '../../../screens/dashboard/session/BookNew';
import { toast } from 'react-toastify';

const slotsCreator = (start, end, available) => {
  return Array.from({ length: end - start }, (_, index) => {
    const hour = start + index;
    return {
      id: index,
      formattedTime: formatTime(hour),
      start: hour,
      available: available && available[hour] === 'not-booked',
    };
  });
};

const checkAvailability = (start, duration, available) => {
  return Array.from({ length: duration }).every(
    (_, i) => available[start + i] === 'not-booked',
  );
};

const intervalCreator = (start, end) =>
  Array.from({ length: end - start }, (_, i) => i + 1);

const TimeCard = React.memo(
  ({ content, id, selected, setSelected, disabled, ...props }) => (
    <button
      disabled={disabled}
      className={`
      w-full lg:w-[84px] h-9 flex items-center justify-center border border-gray-4 font-semibold text-xs rounded-lg
      ${
        disabled
          ? 'border-gray-4/30 text-renaissance-black/30 dark:text-renaissance-dark-black/30'
          : ''
      }
      ${
        selected === id
          ? 'bg-primary-green/30 !text-primary-green !border-primary-green dark:!border-primary-dark-green dark:!text-primary-dark-green'
          : ''
      }
    `}
      onClick={() => !disabled && setSelected(id)}
      {...props}
    >
      {content}
    </button>
  ),
);

const DurationTimePicker = ({
  type,
  selectedPlan,
  setSelectedPlan,
  setSelectedDate,
  selectedDate,
  selectedTime,
  setSelectedTime,
  selectedDuration,
  setSelectedDuration,
  submitHandler,
  coinBalance,
  isLoading,
  available,
  availableLoading,
}) => {
  const { startTime, endTime, coin_price, fiat_price } =
    plans[selectedPlan - 1];
  const [maxDuration, setMaxDuration] = useState(0);

  const now = new Date();

  useEffect(() => {
    setSelectedTime(null);
    setSelectedDuration(0);
  }, [selectedDate, setSelectedTime, setSelectedDuration]);

  useEffect(() => {
    if (selectedTime !== null && available) {
      let max = 0;
      for (let i = 1; i <= endTime - selectedTime; i++) {
        if (checkAvailability(selectedTime, i, available)) {
          max = i;
        } else {
          break;
        }
      }
      setMaxDuration(max);
    }
  }, [selectedTime, endTime, available]);

  const timeSlots = useMemo(
    () => slotsCreator(startTime, endTime, available),
    [startTime, endTime, available],
  );

  const handleTimeSelection = (time) => {
    const timeString = `${selectedDate.toDateString()} ${time.start}:00:00`;
    const timeDateFormat = new Date(timeString);

    if (timeDateFormat > now && time.available) {
      setSelectedTime(time.start);
      setSelectedDuration(0);
    } else {
      toast.error('This time slot is not available');
    }
  };

  return (
    <div className="flex flex-col gap-6 lg:gap-12 ">
      <div className="">
        <p className="mb-4 font-medium text-base text-center lg:text-left">
          {' '}
          {selectedDate.toDateString()}{' '}
          <span className="font-semibold"> - Choose Time</span>
        </p>

        {availableLoading ? (
          <div className="text-sm font-semibold border border-black p-2 rounded-md">
            Loading Available Time...{' '}
          </div>
        ) : (
          <div className=" grid grid-cols-1 lg:grid-cols-4 gap-y-2 lg:gap-y-6 w-full ">
            {timeSlots.map((time) => (
              <TimeCard
                key={time.id}
                id={time.start}
                content={time.formattedTime}
                selected={selectedTime}
                setSelected={() => handleTimeSelection(time)}
                disabled={
                  !time.available ||
                  new Date(
                    `${selectedDate.toDateString()} ${time.start}:00:00`,
                  ) <= now
                }
              />
            ))}
          </div>
        )}
      </div>

      {type !== 'reschedule' && typeof selectedTime === 'number' && (
        <div>
          <p className="mb-4 font-medium text-base text-center lg:text-left">
            Choose Hours:{' '}
            <span className="text-xs">
              (Max Duration: {maxDuration} hour(s) )
            </span>
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-y-2 lg:gap-y-6 w-full">
            {intervalCreator(1, maxDuration + 1).map((duration) => (
              <TimeCard
                key={duration}
                id={duration}
                content={`${duration} Hours`}
                selected={selectedDuration}
                setSelected={setSelectedDuration}
              />
            ))}
          </div>
        </div>
      )}

      <div>
        <Button
          onClick={submitHandler}
          disabled={!selectedDuration || !selectedPlan || selectedTime === null}
          className={`!w-full !border-0 px-1 lg:!px-8 !text-primary-white 
                    ${
                      !selectedDuration ||
                      !selectedPlan ||
                      selectedTime === null
                        ? '!bg-gray-4'
                        : ' !bg-primary-green '
                    }`}
          size="xlarge"
        >
          {isLoading
            ? 'Processing...'
            : `Book Session for  ${selectedDate.toDateString()}`}
        </Button>
        {/* {type !== 'reschedule' && <p className='font-semibold mt-6'> Coin Balance: {coinBalance} Coins </p>} */}
      </div>
    </div>
  );
};

export default DurationTimePicker;
